<script setup lang="ts">
const props = defineProps<{
  content: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const content = getSlotContent('content');
</script>
<template>
  <CmsGenericElement
    :content="{
      ...content,
      backgroundColor: props.content.backgroundColor,
      backgroundImage: props.content.backgroundMedia?.url,
    }"
    class="cms-block-ln-ecrm-form__block"
  />
</template>
